import React, { useState, useEffect } from 'react';

import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { BrowserRouter as Router, Link } from 'react-router-dom';

import { BiCommentDetail } from 'react-icons/bi';
import { RiSurgicalMaskLine } from 'react-icons/ri';
import { AiOutlineBarChart } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import './index.scss';

const CovidPieNationChart = ({ covidData, isKo }) => {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState(null);

  useEffect(() => {
    if (!covidData) return;
    const filterData = covidData.filter((data) => data.name !== '');
    setData(filterData);
  }, [covidData, isKo]);

  const COLORS = ['#4c3cd6', '#4e9bff', '#03cab2', '#FFBB28', '#aaa', '#666'];

  const RADIAN = Math.PI / 180;
  const RenderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    cate,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        <text
          x={percent > 0.06 ? x : x - 20}
          y={y - 20}
          fill={percent > 0.04 ? 'white' : 'none'}
          fontSize={percent > 0.07 ? 12 : 10}
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {`${cate[index].name}`}
        </text>
        <text
          x={percent > 0.06 ? x : x - 20}
          y={y}
          fill={percent > 0.04 ? 'white' : 'none'}
          fontSize={percent > 0.07 ? 16 : 13}
          fontWeight="600"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(1)}%`}
        </text>
      </>
    );
  };

  const [isAnimation, setIsAnimation] = useState(true);

  return (
    <section className="nation-piechart">
      <h3>{t('continent')}</h3>
      {data && (
        <article className="chartbox">
          <ResponsiveContainer width="100%" height={220}>
            <PieChart width={220} height={220}>
              <Pie
                data={data}
                cx={'50%'}
                cy={100} //사이즈 줄일때 여기도 같이!
                outerRadius={100} //사이즈 줄일때 여기도 같이!
                label={<RenderCustomizedLabel cate={data} />}
                startAngle={90}
                endAngle={450}
                fill="#8884d8"
                stroke="none"
                dataKey="value"
                isAnimationActive={false}
                // isAnimationActive={isAnimation}
                // onAnimationEnd={() => setIsAnimation(false)}
                labelLine={false}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>

          <ul className="group-info">
            {data
              .sort((a, b) => b.value - a.value)
              .map((nation, i) => (
                <li key={i}>
                  <strong style={{ color: COLORS[i] }}>
                    <span className="nation">{nation.name}</span>

                    <span className="per">
                      {(
                        (nation.value / data.reduce((a, b) => a + b.value, 0)) *
                        100
                      ).toFixed(2)}
                      %
                    </span>
                  </strong>
                  <span className="amount">
                    {nation.value.toLocaleString()} {isKo ? '명' : ''}
                  </span>
                </li>
              ))}
          </ul>
        </article>
      )}
      <div className="banner">
        <a
          href="https://awesome-ui.netlify.app/covid"
          className="btn-korea"
          target="_blank"
        >
          <div>
            <AiOutlineBarChart />
            {t('koreaStatus')}
          </div>

          <MdKeyboardArrowRight />
        </a>

        <Link to="/about" aria-label="About covid-19 ">
          <div>
            <BiCommentDetail />
            {t('btnAbout')}
          </div>

          <MdKeyboardArrowRight />
        </Link>

        {/* <a href="https://coupa.ng/bQK3Y1" className="btn-mask" target="_blank">
          <div>
            <RiSurgicalMaskLine />
            {t('btnMask')}
          </div>

          <MdKeyboardArrowRight />
        </a> */}
      </div>
    </section>
  );
};

export default CovidPieNationChart;
